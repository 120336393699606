.appHeader {
  flex-grow: 0;
  max-height: 40px;
  border-width: 0 0 1px 0;

  &__logo {
    margin: 10px 30px;

    svg {
      width: 100px;
      height: 20px;
    }
  }
}
