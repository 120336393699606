.slider {
  &__container {
    transition: margin 0.3s linear;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
  }
}
#privacyText {
  line-height: 24px !important;
}
#privacyLabel {
  white-space: pre-wrap;
}
