:focus {
  outline: none !important;
}

#ServiceStatusBar {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 1000;

  [dir="rtl"] & {
    right: auto;
    left: 20px;
  }

  @media (max-width: 48em) {
    top: 10px;
  }
}

.loader-icon {
  animation: rotating 4s infinite cubic-bezier(0.55, 0, 0.1, 1);
}

.popover-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 41, 52, 0.4);
  backdrop-filter: blur(4px);
  z-index: -1;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
