.avRemoveExcludedItemPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 700px;
  align-items: center;

  &__title {
    margin-bottom: 8px;
    align-self: flex-start;
  }

  &__content {
    margin-bottom: 8px;
    &__info {
      display: flex;
      align-items: center;
      align-self: start;
      &__icon {
        margin-right: 5px;
      }
    }
  }

  &__buttons {
    margin-top: 16px;
    align-self: flex-end;
  }
}
