.PopupFrame {
  max-width: 500px;
  text-align: center;
  .PopupTitle {
    font-size: 23px;
    border-bottom: solid 1px silver;
    padding: 20px 0;
  }
  .PopupBody {
    padding: 0 25px;
    margin: 0 0 30px 0;
    // background-color: #f0f0f0;
    .PopupMessage {
      padding: 20px;
      font-size: 25px;
    }
    .RatingButtons {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      .btn {
        min-width: auto;
        border-radius: 20px;
        // border-color: #444;
        border-width: 6px;
        // color: #666;
        font-size: 25px;
        height: 60px;
      }
    }
    .RatingDescription {
      display: flex;
      justify-content: space-between;
      padding: 0 0 20px;
      > div {
        max-width: 100px;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    .TextInputFrame {
      textarea {
        width: 100%;
        padding: 10px;
        min-height: 120px;
      }
    }
    .ActionButtons {
      padding: 15px;
    }
  }
}
.popup__container {
  padding: 20px;  
}
.popup__container--md {
  min-width: 550px;
}
