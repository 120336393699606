.secureVpnCard {
  align-items: center;
  display: flex;
  flex: 1;
  padding: 12px;

  &:first-child {
    margin-right: 12px;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  &__textContainer {
    align-self: flex-start;
    flex-grow: 1;
  }

  &__tag {
    min-height: 32px !important; // to override default pegasus values
    min-width: 32px !important; // to override default pegasus values
    padding: 0 !important; // to override default pegasus values

    align-self: flex-start;
    margin-right: 12px;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 12px;
    }
  }
}

@media screen and (max-width: 767px) {
  .secureVpnCard {
    &:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
