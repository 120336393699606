.PersonalInfo {
  &__wellDone {
    position: fixed;
    top: 0;
    left: 40px;
    width: 100%;
    height: 100%;
  }
  &__noBreach {
    margin-top: 13vh;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__noBreachIcon {
      color: #dadada;
      display: flex !important;
      justify-content: center;
      transform: scale3d(1.5, 1.5, 1.5);
      margin-bottom: 10px;
    }
  }
}
