.av {
  &__content {
    margin-bottom: 20px;
    margin-right: 2vw;
    &__info {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &__text {
        margin-left: 10px;
      }
    }
    &__button {
      margin-top: 16px;
      margin-right: 15px;
    }
    &__link {
      margin-left: 3px;
      font-weight: 600 !important;
    }
  }
  &__buttons {
    display: flex;
  }
  &__card {
    width: 43vw;
    margin-right: 2vw;
    display: flex;
    align-items: center;
    height: 58px;
    padding: 10px;
    &__content {
      margin-left: 20px;
      flex-grow: 1;
    }
  }
}
