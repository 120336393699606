#Services {
  .navbarLayout__body {
    padding: 20px;
    overflow-y: initial;
  }

  .card {
    min-width: 360px;
  }
}
