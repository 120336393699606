.wssPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 525px;
  align-items: center;

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    margin-bottom: 16px;
  }
  &__image {
    margin-bottom: 24px;
    height: 120px;
  }
  &__buttons {
    margin-top: 24px;
    align-self: flex-end;
  }
}
