.onBoardingBg {
  > img {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  padding: 0 0 0 36%;
  .onBoardingContent {
    min-height: 500px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.featurePageBg {
  max-width: 1200px;
  margin: 0 auto;
  // @media screen and (max-width: 760px) {
  //   padding: 20px 12px 74px;
  // }
  // padding: 95px 12px 20px;

  // .list__item__content {
  //   width: 100% !important;
  // }
}

// iframe {
//   border: none;
//   margin: 0;
//   z-index: 1000;
//   width: 150px;
//   height: 18px;
//   vertical-align: middle;
// }

.highlighted {
  font-weight: bold;
  border-left: solid 2px navy;
}

.FloatingWarning {
  position: fixed;
  top: 3px;
  right: 190px;
  // transform: translateX(-50%);
  background-color: lightgoldenrodyellow;
  z-index: 1000;
  button {
    background-color: transparent;
    padding: 2px 10px;
    border: none;
  }
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.centerOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.blocker {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.hidden {
  visibility: hidden;
}

.descWrapper {
  max-width: 525px;
  height: 100%;
  align-self: center;
  padding: 0 20px;
}

input::-ms-clear {
  display: none !important;
}

.sidebarContainer {
  width: 100%;
  height: 100%;
  border-width: 0 1px 0 0 !important;
  border-style: solid !important;
  border-color: rgb(209, 213, 223) !important;

  [dir="rtl"] & {
    border-width: 0 0 0 1px !important;
  }
  @media screen and (max-width: 767px) {
    border-width: 1px 0 0 0 !important;
  }
}
