.antivirusSettings {
  &__subtitle {
    color: #212934;
    margin-bottom: 4px;
  }

  &__paragraph {
    margin-bottom: 16px;

    &--moreSpacing {
      margin-bottom: 32px;
    }
  }

  &__rtsContent {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  &__excludedList {
    border: 1px solid #e8e5e5;
    border-radius: 10px;
    padding: 16px 16px 0px 16px;
    // height: 100%;
    &__count {
      width: 100%;
      &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 10px;
      }
    }
    &__addItem {
      align-items: center;
      border: none;
      margin-top: 8px;
      cursor: pointer;
      &__container {
        display: flex;
        align-items: center;
      }
      &__icon {
        margin-right: 16px;
      }
    }
    &__content {
      &__showContent {
        height: auto !important;
      }
      &__item {
        &__icon {
          margin-right: 20px;
        }
        &__name {
          display: flex;
          flex-grow: 1;
        }
      }
    }
  }
  &__quarantined {
    margin-top: 16px;
  }
}
