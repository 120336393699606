label[for="check16plusConsent"] {
  padding: 0 0 0 2px;

  [dir="rtl"] & {
    padding: 0 2px 0 0;
  }

  span {
    white-space: initial;
  }
}
