.scanTypesPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 525px;
  &__title {
    margin-bottom: 24px;
    border-bottom: 1px solid #dedbdb;
    &__text {
      margin-bottom: 20px;
    }
  }
  &__info {
    margin: 16px 0px 16px 0px;
    display: flex;
    align-items: center;
    &__text {
      margin-left: 8px;
    }
  }
  &__buttonsBar {
    text-align: right;
    border-bottom: 1px solid #dedbdb;
    margin-bottom: 24px;
    &__button {
      margin-bottom: 16px;
      
    }
  }
}
