.preExpiryPopup {
    display: flex;
    flex-direction: column;
    width: 506px;
    padding: 24px;

    &__header {
        margin-bottom:8px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__content {
        margin-bottom:20px;
    }
    &__buttons {
        margin-bottom:8px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}