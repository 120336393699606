.fiveReasonsPopup {
  padding: 24px;
  max-width: 525px;

  &__title {
    margin-bottom: 24px;
  }

  &__reasonContainer {
    display: flex;
    margin-bottom: 24px;

    &__text {
      margin: 0 16px;
    }
  }
}
