.secureVpnPopup {
  padding: 24px;
  display: flex;
  flex-direction: column;
  max-width: 525px;

  &__icon {
    align-self: center;
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__paragraph {
    margin-bottom: 16px;

    + .secureVpnPopup__paragraph {
      margin-bottom: 24px;
    }
  }
}
