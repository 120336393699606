.ScanProgress {
  padding-top: 7vh;
  &__gauge {
    justify-content: center;
  }
  &__content {
    text-align: center;
    padding: 24px;
  }
  &__threats {
    display: flex;
    justify-content: center;
    margin-top: 2vh;

    &__container {
      margin-right: 2vw;
    }
  }
  &__buttons {
    float: right;
    margin-right: 2vw;
    &__button {
      margin-right: 30px;
    }
  }
  &__info {
    display: flex;
    justify-content: center;
    padding: 5vh;
    height: 33vh;
  }
}
.progress-gauge__counter {
  font-weight: bold;
}
