.addressedBreachPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 525px;

  &__assetList {
    margin-bottom: 8px;
    background: #f5f6fa !important;
    border-radius: 12px;  
  }

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    margin-bottom: 16px;
  }

  &__subheader {
    margin: 12px 0px 12px 0px;
    color: #212934 !important;

  }

  &__buttonBar {
    border: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #d1d5df;
    text-align: right;
    margin-top: 4vh;
    &__button {
      margin-top: 12px;
    }
  }
}
