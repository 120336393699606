.removeEmailPopup {
  &__container {
    max-width: 525px;
    padding: 24px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__email {
    font-weight: 600 !important;
    margin-bottom: 8px;
  }

  &__buttons {
    margin-top: 24px;
    text-align: right;

    button:first-child {
      margin-right: 24px;
    }
  }
}

.buttonIcon {
  margin-right: 8px;
}
