.secureVpn {
  &__titleIcon {
    color: #848faa;
    cursor: pointer;
    margin: 0 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__switch {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }

  &__cardContainer {
    display: flex;
    flex-wrap: nowrap;
  }
}

#secureVPN .navbarLayout__body {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .secureVpn {
    &__cardContainer {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}
