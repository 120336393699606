@media screen and (max-width: 767px) {
  #Settings {
    .grid__row {
      .grid__col:first-child {
        order: 1;
      }
    }
  }
}

.overflow {
  overflow: overlay !important;

  // reset overflow back to default for UWP
  @supports (-ms-ime-align: auto) {
    overflow: auto !important;
  }
}

.settingsMenu__icon {
  color: rgb(132, 143, 170);
}

.subscriptionInformation {
  &__status {
    font-style: italic;
  }
}
