.identityLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__basePanel {
    height: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      height: auto;
      flex-wrap: nowrap;
    }
  }
  &__assetExplorer {
    display: flex;
    height: 100%;
    overflow-y: overlay;
    @supports (-ms-ime-align: auto) {
      // IE specific styles
      overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
      height: auto;
      overflow-y: visible;
    }
  }

  &__detailsBack {
    position: absolute;
    padding: 0 12px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: overlay;
    @supports (-ms-ime-align: auto) {
      // IE specific styles
      overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
      height: auto;
      overflow-y: visible;
    }
    // justify-content: start;
    background-color: white;
    padding: 24px;
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0 20px 0;
    }
    &__content {
      overflow: visible;
      padding: 0px 20px 0px 40px;
      border: solid;
      border-width: 0px 0px 1px 0px;
      border-color: #d1d5df;

      &__assetList {
        border: solid;
        border-width: 1px 0px 1px 0px;
        border-color: #d1d5df;
        min-height: 100px;
      }
      &__link {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
        margin-left: 10px !important;
        &__label {
          color: #1671ee !important;
        }
      }
      &__caption {
        padding: 12px 0;
        max-width: 476px;
        text-align: center;

        &__link {
          font-weight: 700 !important;
        }
      }
    }
  }
}
