.CreateAccount {
  width: 100vw;
  overflow: auto;
  background-color: #212934;
  .welcomeWrapper {
    height: 100vh;
  }
  .brand {
    max-width: 82px;
    margin-bottom: 55px;
  }

  .welcome-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100%;
    width: 100%;
    background-image: url(../_assets/images/onboard/welcome/welcome.svg);
    transform: scale3d(0.8, 0.8, 0.8);

    .app-identityportal & {
      background-image: url(../_assets/images/onboard/welcome/idps_sign_in_splash.svg);
    }
  }

  .defaultIntro {
    .app-identityportal & {
      display: none;
    }
  }

  .idpsIntro {
    display: none;
    .app-identityportal & {
      display: block;
    }
  }

  @media screen and (max-height: 700px) {
    .welcome-image {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .hide-in-portrait {
      display: none !important;
    }

    .loader {
      position: fixed;
      margin: auto;
      width: 100% !important;
      top: 0;
      bottom: 0;
      right: 0;
      height: 300px;
    }
  }
  .trial-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    &__content {
      padding: 0px 54px;
      &__desc {
        padding: 24px 0px 24px 0px;
        &__info {
          align-items: center;
          display: flex;
          padding: 8px 0px 8px 0px;
        }
      }
    }
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__signIn {
        padding-bottom: 12px;
      }
      &__noCard {
        color: #6d7895;
        font-style: italic;
      }
    }
  }
  .hide-content {
    visibility: hidden;
    overflow: hidden;
    height: 0%;
  }
}
