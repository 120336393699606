.dialog-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  background-color: white;

  .content-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .dialog-title {
    display: flex;
    align-items: center;
    &__text {
      margin: 0px 0px 0px 25px;
    }
  }

  .left-col {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 56em) {
      display: none;
    }
  }

  .dialog-layout-avatar {
    text-align: center;

    img {
      margin: 5vw;
      width: 30vw;
    }
  }

  .right-col {
    padding: 0 4.5vw;

    .dialog-layout-back {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 10vh;
    }

    .dialog-layout-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 75vh;
      overflow: overlay;
      @supports (-ms-ime-align: auto) {
        // IE specific styles
        overflow: auto;
      }

      max-width: 640px;
      @media screen and (max-height: 42em) {
        justify-content: start;
        padding-top: 5vh;
      }

      .dialog-layout-avatar {
        height: 25vh;
        width: auto;
        margin-bottom: 2vh;
        img {
          height: 100%;
          margin: 0;
        }
        @media screen and (max-width: 30em),
          screen and (min-width: 56em),
          screen and (max-height: 48em) {
          display: none;
        }
      }
    }
  }
  .dialog-layout-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 15vh;

    .buttons-bar {
      margin: 0vw 5vw 0vw 5vw;
      @media screen and (max-width: 56em) {
        margin: 0vw 14vw 0vw 14vw;
      }
      button {
        margin: 0 2vw;
      }
    }
  }
}

#PrivacyPage.dialog-layout {
  .right-col {
    .dialog-layout-content {
      justify-content: start;
      padding-right: 1.5rem;
    }
  }
}
#TrialAccountCreationCompletePage.dialog-layout {
  .right-col {
    .dialog-layout-content {
      @media screen and (max-height: 42em) {
        justify-content: center;
        padding-top: 5vh;
      }
    }
    .dialog-layout-buttons {
      .buttons-bar {
        text-align: end;
      }
    }
  }
  .left-col {
    .dialog-layout-avatar {
      img {
        margin: 0vw 5vw 5vw 5vw;
        width: unset;
      }
    }
  }
}
#AvPage.dialog-layout {
  background-color: unset;
}
