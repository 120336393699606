.tellMeMore {
  display: flex;
  padding: 97px 24px 24px 0;
  max-width: 666px;
  overflow: hidden;

  &__sliderRow {
    display: flex;
    min-height: 244px;
    margin-bottom: 36px;
    width: 100%;
  }

  &__sliderContainer {
    display: flex;
    width: 48%;
    overflow: hidden;
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin 0.3s linear;
    width: 100%;
    flex-shrink: 0;
    text-align: center;
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 52%;
  }

  &__paginationContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 24px;
  }
}

@media screen and (max-width: 48em) {
  .tellMeMore {
    max-width: 512px;
    padding-top: 43px;
    padding-left: 24px;

    &__sliderRow {
      flex-direction: column;
      align-items: center;
      min-height: auto;
    }

    &__sliderContainer,
    &__textContainer {
      width: auto;
    }

    &__sliderContainer {
      margin-bottom: 43px;
    }

    &__paginationContainer {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    &__pagination {
      margin-top: 12px !important;
    }
  }
}
