.scanResult {
  &__content {
    margin-bottom: 20px;
    &__link {
        margin-left: 3px;
        font-weight: 600 !important;
      }
  }
  &__buttons {
    display: flex;
  }
}
