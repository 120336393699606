.syncSubscriptionPopup {
  max-width: 438px;
  min-height: 400px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  &__image {
    align-self: center;
    margin-bottom: 28px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    margin-bottom: 12px;
  }

  &__link {
    display: inline;
    cursor: pointer;
  }

  &__buttonContainer {
    align-self: flex-end;
    margin-top: 12px;

    button:first-child {
      margin-right: 24px;
    }
  }

  &__apiStatus {
    &Container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 390px; // on success status, the paragraph is too small to naturally have this width, which would cause the popup to look strange.

      @media screen and (max-width: 767px) {
        min-width: 0;
      }
    }

    &Icon {
      &--success,
      &--notFound,
      &--error,
      &--expired {
        font-size: 40px !important;
      }

      &--success {
        color: #00a865;
      }

      &--notFound {
        color: #1671ee;
      }

      &--error {
        color: #e32224;
      }

      &--expired {
        color: #f06b00;
      }
    }

    &Label {
      margin-top: 24px;
      margin-bottom: 4px;
    }

    &Button {
      align-self: flex-end;
    }
  }
}
