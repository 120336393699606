.breachActions {
  &__container {
    display: flex;
    text-align: center;
    margin: 2vw 1vw;
    justify-content: flex-end;
    &--withSpaceBetween {
      display: flex;
      justify-content: space-between;
      margin: 48px 0;
      align-self: flex-end;
    }
  }

  &__alreadyFixedIt--withMargin {
    margin-left: 24px;
  }

  &__changePassword {
    margin-top: auto !important;
    margin-bottom: auto !important;

    &__icon {
      margin-left: 8px;
    }
  }
}
