.avExcludeItemPopup {
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 560px;
  
    &__title {
      margin-right: 7vw;
      margin-bottom: 8px;
      align-self: flex-start;
    }
  
    &__content {
      margin: 8px 0px 8px 0px;
      &__info {
        display: flex;
        align-items: center;
        &__icon {
          margin-right: 5px;
        }
      }
    }
    
    &__buttons {
      margin-top: 16px;
      align-self: flex-end;
    }
  }