.encryptedPasswordPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 525px;

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    margin-bottom: 16px;
  }

  &__button {
    margin-top: 8px;
    margin-left: auto;
  }
}
