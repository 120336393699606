.dwsLearnMorePopup {
  max-width: 560px;
  padding: 24px;

  &__container {
    height: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__paragraph {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }
}

.pgs-modal__close {
  z-index: 2;
}
