//to override pegasus styles
.banner {
  border-radius: 0 !important;

  &-content {
    width: 99vw;
    display: flex;
    justify-content: space-between;
  }
}
