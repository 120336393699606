.identitySettings {
  &__subtitle {
    color: #212934;
    margin-bottom: 4px;
  }

  &__paragraph {
    margin-bottom: 16px;

    &--moreSpacing {
      margin-bottom: 32px;
    }
  }

  &__icon {
    margin-right: 12px;
  }

  &__asset {
    &List {
      margin-bottom: 24px !important;
      width: 100%;
    }

    &Item {
      display: flex;
      align-items: center;
      border-top: 1px solid #d1d5df;
      padding: 14px 24px !important;
      width: 100%;

      &__more {
        color: #848faa;
      }

      span {
        align-items: center;
      }

      &__email {
        display: flex;
        flex-grow: 1;
      }

      .icon-chevron-right-outline {
        display: none;
      }
    }
  }

  &__aboutIcon {
    margin-right: 8px;
  }

  &__removeMenu {
    &__container {
      border-radius: 8px;
      overflow: hidden;
      min-width: 225px;
    }

    &__item {
      padding: 20px 24px !important;
      justify-content: space-between;

      &:focus {
        border: 1px solid transparent !important;
      }
    }

    &__icon {
      color: #e10a39;
    }
  }

  &__textBold {
    font-weight: 700 !important;
  }
}
