.FeedbackSettingsPage {
  &__negativeButton {
    border: 1px solid #d1d5df !important;
    border-radius: 12px 0px 0px 12px !important;

    &Selected {
      @extend .FeedbackSettingsPage__negativeButton;
      background: #ffdbde !important;
      border-color: #e10a39 !important;
    }
  }

  &__neutralButton {
    border-radius: 0px !important;
    border: 1px solid transparent !important;
    border-top: 1px solid #d1d5df !important;
    border-bottom: 1px solid #d1d5df !important;

    &Selected {
      @extend .FeedbackSettingsPage__neutralButton;
      background: #fde8e2 !important;
      border-color: #f06b00 !important;
    }
  }

  &__positiveButton {
    border: 1px solid #d1d5df !important;
    border-radius: 0px 12px 12px 0px !important;

    &Selected {
      @extend .FeedbackSettingsPage__positiveButton;
      background: #d6fff0 !important;
      border-color: #0ca77d !important;
    }
  }

  &__supportPageIcon {
    font-size: 107px !important;
  }
}

[dir="rtl"] {
  .FeedbackSettingsPage {
    &__negativeButton {
      border-radius: 0px 12px 12px 0px !important;
    }

    &__positiveButton {
      border-radius: 12px 0px 0px 12px !important;
    }
  }
}
