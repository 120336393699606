.welcomeCarousel {
  width: 100vw;
  height: 100vh;

  &__grid {
    width: 80%;
  }

  &__row {
    height: 100vh;
    overflow-y: auto;
  }

  &__image {
    width: 70%;
  }
}

.skipButton {
  position: absolute !important;
  top: 16px;
  right: 64px;
  min-width: 65px;
}
