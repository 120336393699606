.assetAddedPopup {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 525px;

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    margin-bottom: 16px;
    &__label {
      display: flex;
    }
  }
  &__image {
    margin-bottom: 24px;
    height: 120px;
  }
  &__label {
    white-space: pre-wrap;
  }
}
