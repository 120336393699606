.dashboard-card-header__menu {
  .menu__btn {
    border: 1px solid #e6e9f0;
  }

  i {
    font-weight: 600;
  }
}

@media screen and (max-width: 48em) {
  .dashboardCards {
    .card {
      .grid__row {
        flex-direction: column;
        .grid__col {
          img {
            max-height: 250px;
          }
        }
      }
    }
  }
}
