.navbarLayout {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;

  &__navbar {
    min-width: 40px;
  }

  &__content {
    flex-grow: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    &--no-overflow {
      overflow: initial !important;
    }
  }

  &__header {
    min-height: 40px;
    background-color: white;
  }

  &__body {
    flex-grow: 1;
    overflow-y: overlay;
    @supports (-ms-ime-align: auto) {
      // IE specific styles
      overflow-y: auto;
    }

    > div {
      //force root child to fill the parent width
      width: 100% !important; //TODO: use different approach
    }

    .grid-container {
      width: 100%; //TODO: use different approach
    }
  }
}
