.logout {
    &__btn {
        position: absolute;
        right: 5px;
        top: 5px;
        background: transparent !important;
        opacity: .5;
        &:hover, &:active {
            opacity: 1;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        }
    }
}