.subscriptionBar {
  align-items: center;
  display: flex;
  height: 46px;
  justify-content: space-between;
  max-width: 640px;
  margin: auto;
  padding: 0 16px 0 8px;

  &__container {
    display: flex;
    align-items: center;
  }

  &__counter {
    background: #1671ee;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    margin-right: 8px;
    position: relative;
    width: 33px;

    > div {
      color: #fff !important;
    }

    &--countdown {
      @extend .subscriptionBar__counter;
      background: rgba(22, 113, 238, 0.15);
      > div {
        color: #1671ee !important;
      }
    }

    &--danger {
      @extend .subscriptionBar__counter;
      background: rgba(225, 10, 57, 0.15);
      > div {
        color: #e10a39 !important;
      }
    }

    &--lastDay {
      @extend .subscriptionBar__counter;
      background: #e10a39;
    }
  }

  &__progressGaugeContainer {
    position: absolute;
    width: 33px;
    height: 33px;

    circle:nth-child(2) {
      stroke-width: 8 !important;
    }
  }

  &__subscribeNow {
    padding-right: 12px;
    border-right: 1px solid #dadada;
    margin-right: 16px;
  }

  &__syncButtonIcon {
    color: #848faa;

    &:hover {
      color: #1671ee;
    }
  }
}
