.cancelScanPopup {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 525px;
    align-items: center;
  
    &__title {
      margin-bottom: 8px;
      align-self: flex-start;
    }
  
    &__content {
      margin-bottom: 16px;
    }
    
    &__buttons {
      margin-top: 16px;
      align-self: flex-end;
    }
  }
  