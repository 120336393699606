#Dashboard {
  .navbarLayout__body {
    padding: 20px;
  }
}
.dashboardCards {
  .progress-gauge {
    margin-top: 20vh;
  }
  .dashboard-card {
    max-width: 624px !important;
    margin: 24px auto 0 auto;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 80px;
    }
    &.header-card {
      margin-top: 60px;
      min-height: 35px !important;
      padding: 10px 20px !important;
      color: #5a6175;
      font-weight: 600;
    }
  }
}

#Dashboard {
  .card {
    min-width: 360px;
  }
}
