.virtualLocationPopup {
  max-width: 525px;
  padding: 24px;

  &__title {
    margin-bottom: 4px;
  }

  &__subtitle {
    color: #848faa !important;
    margin-bottom: 16px;
  }

  &__locationContainer {
    padding: 16px 0;
    border-top: 1px solid #d1d5df;
    border-bottom: 1px solid #d1d5df;
    margin: 16px 0;
  }

  &__countryName {
    color: #212934;
    & + .virtualLocationPopup__question {
      margin-top: 16px;
    }
  }

  &__question {
    color: #212934;
    margin-bottom: 4px;
  }
}
