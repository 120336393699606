.passwordTipsPopup {
  display: flex;
  flex-direction: column;
  max-width: 525px;
  padding: 24px;

  &__title,
  &__content {
    margin-bottom: 8px;
    display: flex;
  }

  &__bullet {
    width: 24px;
    text-align: center;
    display: inline-block;
  }

  &__button {
    margin-top: 24px;
    margin-left: auto;
  }
}
